import { Link } from 'gatsby';
import React from 'react';
import logo from '../img/Simiancraft-logo-textonly-w.png';
class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: ''
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active'
            })
          : this.setState({
              navBarActiveClass: ''
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar is-dark"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            {/* <img
              src={logo}
              alt="Simiancraft: WhizBang in your Browser."
              className="is-marginless"
            ></img> */}
            <strong className="is-branded" style={{ fontSize: '1.5rem' }}>
              Simiancraft
            </strong>
          </Link>

          <a
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className="navbar-menu">
          {/* <div className="navbar-start">
            <Link to="/projects" className="navbar-item">
              Work
            </Link>
          </div> */}
        </div>
      </nav>
    );
  }
}

export default Navbar;
