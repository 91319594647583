import React, { Component } from 'react'

import { Link } from 'gatsby'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import logo from '../img/logo.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container level">
          <div className="level-left">
            <div className="level-item">
              <div>
                <strong className="is-branded" style={{ fontSize: '3rem' }}>
                  Simiancraft
                </strong>
              </div>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item ">
              <div>
                <a
                  className="icon is-large"
                  href="mailto:info@simiancraft.com?subject=Simiancraft%20Consultation%20Scheduling&amp;body=Are%20you%20available%20on%20Friday%20at%209am%3F%20If%20not%2C%20what%20works%20best%20for%20you%3F"
                >
                  <i className="fa fa-envelope-o fa-2x"></i>
                </a>

                <a
                  className="icon  is-large"
                  href="https://www.twitter.com/5imian"
                >
                  <i className="fa fa-twitter fa-2x" />
                </a>

                <a
                  href="https://www.github.com/simiancraft"
                  className="icon is-large fa-2x"
                >
                  <i className="fa fa-github" />
                </a>

                <a
                  href="https://www.linkedin.com/company/simiancraft/"
                  className="icon is-large"
                >
                  <i className="fa fa-linkedin fa-2x"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
